import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {Card, Spin} from 'antd';
import {invitation} from '../../services/API';
import {InviteAcceptSuccessful} from './InviteAcceptSuccessful';
import {InviteError} from './InviteError';

// @ts-ignore
import * as styles from './Invite.module.less';
import {getInviteInfo, resetInviteInfo} from '../../utils/InviteUtils';
import {useAuth} from '../Auth/AuthContext';
import cx from 'classnames';

export const Invite: FC = () => {
  const [acceptedOrgUrl, setAcceptedOrgUrl] = useState(undefined);
  const [initialLoad, setInitialLoad] = useState(true);
  const [message, setMessage] = useState('Loading invitation');
  const [error, setError] = useState('');

  const {getAccessTokenSilently, user, loginWithRedirect, logout, isAuthenticated, isLoading} = useAuth();

  const acceptInvite = async () => {
    try {
      const {code, tenantId, screen_hint, login_hint} = getInviteInfo({isNew: true});
      let token: string;
      try {
        token = await getAccessTokenSilently();
      } catch (e) {
        // do nothing - only available in auth0
      }

      if (isAuthenticated && login_hint && user.email !== login_hint) {
        // if the user is logged in as a different user than the invited user,
        // log them out, so they can log in as the correct user
        return await logout({returnTo: window.location.href});
      }

      setMessage('Accepting invitation');
      const invitationResponse = await invitation(code, tenantId, token);

      if (invitationResponse.status === 200) {
        const {location = ''} = await invitationResponse.json();
        setAcceptedOrgUrl(location);
        resetInviteInfo();
      } else if (invitationResponse.status === 401 || invitationResponse.status === 403) {
        return await loginWithRedirect({
          login_hint: login_hint || '',
          screen_hint: screen_hint || 'login'
        });
      } else {
        const errorJson = await invitationResponse.json();
        setError(errorJson.message);
        resetInviteInfo();
      }
    } catch (e) {
      setError(e.message);
      resetInviteInfo();
    } finally {
      setInitialLoad(false);
    }
  };

  useEffect(() => {
    if (isLoading) return;
    if (acceptedOrgUrl === undefined && !error) {
      void acceptInvite();
    }
  }, [isLoading]);

  return (
    <main className='main-holder-auth0'>
      <Card className={cx('main-card-auth0', styles.mainCardInvite)}>
        {acceptedOrgUrl !== undefined && <InviteAcceptSuccessful acceptedOrgUrl={acceptedOrgUrl} />}
        {error && <InviteError error={error} />}
        {initialLoad && (
          <>
            <p>{message}</p>
            <Spin className={styles.spinner} key={'spin'} size={'large'} />
          </>
        )}
      </Card>
    </main>
  );
};
