import * as React from 'react';
import {useEffect} from 'react';
import {Result, Spin} from 'antd';
import {navigate} from 'gatsby';
import {useAuth} from '../Auth/AuthContext';
import {getMemberships} from '../../services/API';
// @ts-ignore
import * as styles from './Invite.module.less';

export const InviteAcceptSuccessful = ({acceptedOrgUrl}) => {
  const {getAccessTokenSilently, getIdTokenClaims} = useAuth();

  //Run initial logic
  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently({ignoreCache: true});
      const idToken = await getIdTokenClaims();
      // need to call this to update org cookie
      await getMemberships(token, idToken?.__raw);

      if (acceptedOrgUrl) {
        return (window.location = acceptedOrgUrl);
      }
      await navigate('/organizations');
    })();
  }, []);

  return (
    <Result
      status='success'
      title='Successfully accepted invitation'
      subTitle='We are loading your new account information and you will be redirect shortly.'
      className={styles.result}
      extra={[<Spin key={'spin'} size={'large'} />]}
    />
  );
};
