import * as React from 'react';
import {Result} from 'antd';
// @ts-ignore
import * as styles from './Invite.module.less';

export const InviteError = ({error}) => {
  return (
    <Result
      status='error'
      title={error}
      subTitle='There is something wrong with this link please contact support@cribl.io or join or community channel for help.'
      className={styles.result}
      extra={<a href='/logout'>Log Out</a>}
    />
  );
};
